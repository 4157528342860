// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/Impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/Kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/PrivacyPolicy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-quiz-page-tsx": () => import("./../../../src/pages/QuizPage.tsx" /* webpackChunkName: "component---src-pages-quiz-page-tsx" */),
  "component---src-pages-quiz-success-tsx": () => import("./../../../src/pages/QuizSuccess.tsx" /* webpackChunkName: "component---src-pages-quiz-success-tsx" */)
}

